import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GuidedTourProvider from "contexts/GuidedTourContext";
import { appWithTranslation } from "next-i18next";
import Script from "next/script";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { isProduction } from "../components/tools";
import { AlertAttentionProvider } from "../contexts/AlertAttentionContext";
import { AlertContextProvider } from "../contexts/AlertContext/parent";
import { AuthProvider } from "../contexts/AuthContext";
import { ConstantsProvider } from "../contexts/ConstantsContext/parent";
import { DimensionProvider } from "../contexts/DimensionContext";
import { FilePreviewProvider } from "../contexts/FilePreviewContext/parent";
import { GoogleDrivePreviewProvider } from "../contexts/GoogleDrivePreviewContext/parent";
import { InvoiceProvider } from "../contexts/InvoiceContext";
import { LayoutProvider } from "../contexts/Layout/parent";
import TaskProvider from "../contexts/TaskContext/parent";
import { TimerProvider } from "../contexts/TimerContext";
import { ToasterProvider } from "../contexts/ToasterContext";
import TranslationProvider from "../contexts/TranslationContext/parent";
import ErrorBoundary from "../errorBoundaries";
import SEOComponents from "../pageComponents/script/seo";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/draftjs.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/summarydetails.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";
import { useRouter } from "next/router";

const OpenReplay = ({ children }) => {
  const __html = `
  var initOpts = {
    projectKey: "pLvbuEtgrgC1obMgFoLj",
    ingestPoint: "https://openreplay.transfez-jack-engineering.xyz/ingest",
    defaultInputMode: 1,
    obscureTextNumbers: true,
    obscureTextEmails: true,
    network: {
      capturePayload: true,
      ignoreHeaders: true,
      sanitizer: (res) => {
        const rawObj = JSON.parse(res.request.body);
        let obj = {};

        const keys = Object.keys(rawObj);

        keys.forEach((key) => {
          const isPassword = key.includes("pass");
          const isPin = key.includes("pin");

          const isPass = !isPassword && !isPin;

          if (!isPass) return;
          obj[key] = rawObj[key];
        });

        res.request.body = obj;
        return res;
      },
    },
  };
  var startOpts = { userID: "" };
  (function(A,s,a,y,e,r){
    r=window.OpenReplay=[e,r,y,[s-1, e]];
    s=document.createElement('script');s.src=A;s.async=!a;
    document.getElementsByTagName('head')[0].appendChild(s);
    r.start=function(v){r.push([0])};
    r.stop=function(v){r.push([1])};
    r.setUserID=function(id){r.push([2,id])};
    r.setUserAnonymousID=function(id){r.push([3,id])};
    r.setMetadata=function(k,v){r.push([4,k,v])};
    r.event=function(k,p,i){r.push([5,k,p,i])};
    r.issue=function(k,p){r.push([6,k,p])};
    r.isActive=function(){return false};
    r.getSessionToken=function(){};
  })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
`;

  const inactiveArr = ["internal", "localhost"];

  const isActive = !inactiveArr.find((string) =>
    window.location.href.includes(string)
  );

  return (
    <>
      {isActive && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html,
          }}
        />
      )}
      {children}
    </>
  );
};

const MyApp = ({ Component, pageProps }) => {
  useEffect(initEvents, []);
  const __html = `
  var moeDataCenter = "dc_1"; // Replace "DC" with the actual Data center value from the above table
  var moeAppID = "${process.env.MOENGAGE_APP_ID}"; // Replace "AppID" available in the settings page of MoEngage Dashboard.
  !function(e,n,i,t,a,r,o,d){if(!moeDataCenter||!moeDataCenter.match(/^dc_[0-9]+$/gm))return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/"+moeDataCenter+"/moe_webSdk.min.latest.js","Moengage");
 
  Moengage = moe({
    app_id: moeAppID,
    debug_logs: 0,
    swPath: "/serviceworker.js",
    swScope: '/',
    enableSPA: true
  });`;

  // swPath: "/serviceworker.js",
  // swScope: '/'
  // staging
  // app_id: "PZNUAQ7EEBKP0NAD5XP3QYHA",
  // prod
  // app_id: "YBYJM6XZIM209SM2ZJQ3DFOK",

  const tagManagerArgs = { gtmId: "GTM-567HLBD" };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const { query, pathname, locale, replace } = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    if (locale != "id") return;
    replace(pathname, query, { locale: false });
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/serviceworker.js")
        .then((registration) => console.log("scope is: ", registration.scope));
    }
  }, []);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.NEXT_PUBLIC_FINGERPRINT,
        endpoint: ["https://ZhQbh9K4tf9t.itsjack.com", { default: "endpoint" }],
        scriptUrlPattern: [
          "https://ZhQbh9K4tf9t.itsjack.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          "https://fpnpmcdn.net/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        ],
        region: "ap",
      }}
    >
      <TranslationProvider>
        <DimensionProvider>
          <ToasterProvider>
            <AuthProvider>
              <AlertContextProvider>
                <InvoiceProvider>
                  <AlertAttentionProvider>
                    <TimerProvider>
                      <ConstantsProvider>
                        <GoogleDrivePreviewProvider>
                          <TaskProvider>
                            <FilePreviewProvider>
                              <SEOComponents />
                              {/* {isProduction && ( */}
                              <Script
                                strategy="afterInteractive"
                                dangerouslySetInnerHTML={{
                                  __html,
                                }}
                              />
                              {/* )} */}
                              <LayoutProvider>
                                <ErrorBoundary>
                                  <GuidedTourProvider>
                                    {/* <OpenReplay> */}
                                    <Component {...pageProps} />
                                    {/* </OpenReplay> */}
                                  </GuidedTourProvider>
                                </ErrorBoundary>
                              </LayoutProvider>
                            </FilePreviewProvider>
                          </TaskProvider>
                        </GoogleDrivePreviewProvider>
                      </ConstantsProvider>
                    </TimerProvider>
                  </AlertAttentionProvider>
                </InvoiceProvider>
              </AlertContextProvider>
            </AuthProvider>
          </ToasterProvider>
        </DimensionProvider>
      </TranslationProvider>
    </FpjsProvider>
  );
};

export default appWithTranslation(MyApp);
